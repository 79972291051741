import React from 'react';
import { Router } from '@reach/router';
import { useBeforeUnload } from 'react-use';
import {
  TicketCheckout,
  CardDetails,
  Confirmation,
  CardRedemptionDemo,
} from '../components/routes';
import SelectGiftCardImage from '../components/routes/CardDetails/SelectGiftCardImage';
// import NotFound from './404';

const CardPage = () => {
  useBeforeUnload('Enable', 'You have unsaved changes, are you sure?');
  return (
    <Router basepath="card">
      <CardDetails path=":id" />
      <TicketCheckout path=":id/checkout" />
      <SelectGiftCardImage path=":id/image" />
      <Confirmation path=":id/confirmation" />
      <CardRedemptionDemo path="redemption-demo/:id" />
      {/* <NotFound default /> */}
    </Router>
  );
};

export default CardPage;
